<template>
  <div class="card">
    <div class="card-body pt-0 pb-0">
      <template v-if="user.admin">
        <TabNavigate :items="[
        {id: 'recent-claim', router: `/list-claim/recent`, name: 'Recent Claims'},
        {id: 'watching-claim', router: `/list-claim/watching`, name: 'Watching Claims'},
        {id: 'my-claim', router: `/list-claim/my-claims`, name: 'My Claims'},
        {id: 'delete-claims', router: `/list-claim/deleted-claims`, name: 'Deleted Claims'},
        ]"/>
      </template>
      <template v-else>
        <TabNavigate :items="[
        {id: 'recent-claim', router: `/list-claim/recent`, name: 'Recent Claims'},
        {id: 'watching-claim', router: `/list-claim/watching`, name: 'Watching Claims'},
        {id: 'my-claim', router: `/list-claim/my-claims`, name: 'My Claims'},
        ]"/>
      </template>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import TabNavigate from "@/components/base/common/TabNavigate";
import {computed} from "vue";
import store from "@/store";

export default {
  name: "List",
  components: {TabNavigate},
  setup() {
    const user = computed(() => store.getters.currentUser)
    return {
      user,
    }
  }
}
</script>

<style scoped>

</style>